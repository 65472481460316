<template>
  <div class="w-100 " style="padding: 15px 10px;">
    <section class="w-100 tags" v-if="!isOpenVariant">
      <div class=""
        v-if="areNotAvailableTags"  style="height: 55vh; overflow-y: scroll; overflow-x: hidden;">
        <LoaderComp v-if="isLoadingImport"/>
        <div class="row question-not-font flex-column d-flex justify-content-center align-items-center" v-else>
          
        <LazyImage :src="'/assets/img/noData.svg'" alt="" style="width: 15rem;" class="mt-5" />
        <div class="row text-center px-2">
          <div class="col-12">
             <h3 class="pt-5" style="   font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 21px;

letter-spacing: 0.04em;

color: #4D4950;">No Tags found!</h3>
          </div>
      
        </div>
      </div>
      </div>
      <b-skeleton type="input" v-else-if=isLoading></b-skeleton>
      <div class="" v-else>
        <div class="tagSearchInput col-12 row align-items-center mx-1 pr-3 py-1">
          <div style="cursor: pointer">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path d="M9.5 3C11.2239 3 12.8772 3.68482 14.0962 4.90381C15.3152 6.12279 16 7.77609 16 9.5C16 11.11 15.41 12.59 14.44 13.73L14.71 14H15.5L20.5 19L19 20.5L14 15.5V14.71L13.73 14.44C12.59 15.41 11.11 16 9.5 16C7.77609 16 6.12279 15.3152 4.90381 14.0962C3.68482 12.8772 3 11.2239 3 9.5C3 7.77609 3.68482 6.12279 4.90381 4.90381C6.12279 3.68482 7.77609 3 9.5 3ZM9.5 5C7 5 5 7 5 9.5C5 12 7 14 9.5 14C12 14 14 12 14 9.5C14 7 12 5 9.5 5Z" fill="#73738D"/>
</svg>
            <input type="text" class="mx-2 flex-grow-1" v-model="searchCollection"
              placeholder="Search for Tag" />
          </div>

        </div>
      
      </div>

      <div v-if=isLoading class="col-12  mt-4 d-flex justify-content-end">
        <b-skeleton class="w-25"></b-skeleton>
      </div>
      <div v-else class="col-12  mt-4 d-flex justify-content-end">

        <div class="filterBtn" v-if="!areNotAvailableTags && getTags.length > 0"
          @click.stop="bulkSelectTags">
          {{ isSelectAll?'Select All': 'Unselect All' }}</div>
      </div>
      <div class="w-100 p-3 tagBox" v-if=isLoading style="border:none">
        <div class="skel">
          <div class="mb-2 py-1" v-for="index in 5" :Key="index">
            <div class="d-flex  align-items-center">
              <b-skeleton type="avatar" animation="throb"></b-skeleton>
              <b-skeleton type="input" class="ml-2 w-86" animation="throb"></b-skeleton>
            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="w-100 p-3 tagBox" v-if="(!areNotAvailableTags) && getTags.length > 0" style="border:none">
        <div :class="[isSelectedProduct(tag) ? 'selectedProduct' : '', 'mb-2 py-1,tag-row']"
          v-for="tag, prodIndex in getTags" :key="tag.id">
          <div class="d-flex  align-items-center tag">
            <div class="d-flex  px-0 align-items-center" @click="selectProduct(tag)" style="flex:0.9;cursor:pointer">
              <div class="">
                <div class="custom-control custom-checkbox" role="group">
                  <input type="checkbox" :id="`quest-${prodIndex}`" class="custom-control-input position-static"
                    aria-label="Never" :value="{
                      product_tag_id: tag.id,
                      question_option_id:
                        question.options[
                          selected.optionIndex
                        ].id,
                    }" v-model="
  question.options[
    selected.optionIndex
  ].selected_tags
" 
 @change="selectProduct(tag)" 
 />
                  <label :for="`quest-${prodIndex}`" class="custom-control-label"></label>
                </div>
              </div>
              
              <div class="singleProduct d-flex align-items-center pl-2">
                <p class="title m-0 py-2 text-left">
                  {{ tag.title }}
                </p>
              </div>
            </div>
             <div class=" text-center" style="flex:0.1">
              <button v-b-tooltip.hover title="Products" class="btn" @click.stop="openVariant(tag.title)">
             <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4 19H13L11 21H4C3.5 21 2.97 20.79 2.59 20.41C2.21 20.03 2 19.5 2 19V9H4V19ZM17.63 5.84C17.27 5.33 16.67 5 16 5H8C6.9 5 6 5.9 6 7V15C6 16.1 6.9 17 8 17H16C16.67 17 17.27 16.66 17.63 16.15L22 11L17.63 5.84Z" fill="#4D4950"/>
</svg>
              </button>
            </div>
            

          </div>

        </div>
      
      </div>
    
      <div class="w-100 p-3 tagBox" v-if="!this.isLoading && tags.all.length > 0 && getTags <= 0"
        style="border:none">
        <div class="d-flex flex-column h-100 justify-content-center align-items-center text-center mb-2 py-1">
          <div class="btn" v-b-tooltip.hover.top="'Reset Filter'" @click="searchCollection = ''"> <i
              class="fa-solid fa-arrow-rotate-right"></i></div>
          <h5>No tags were found matching your filter </h5>
        </div>
      </div>
    </section>
    <tag-variant v-else @backFromVariant="backFromVariant()" :isLoadingProducts="isLoadingCollectionProducts"
      :TagProducts="tagProducts" :selected="selected"></tag-variant>
  </div>
</template>
<script>
import LoaderComp from "../../component/LoaderComp.vue";
// import axios from 'axios'
import TagVariant from './TagVariant.vue'
import { mapGetters } from "vuex";
export default {
  props: {
    tags: Object,
    selected: Object,
    question: Object,
    isLoading: Boolean,
    products:Array
  },
  components: {
    TagVariant,
    LoaderComp
  },
  computed: {
    areNotAvailableTags() {
      return this.isLoading == false && this.tags.all.length <= 0 ? true : false
    },
    ...mapGetters(["getEditorSettings", "GetTokenFromMetaTag", "getQuizID","getNavbarData"]),
  getUserType(){
      return this.getNavbarData.userType
    },
      getSortedTags(){
      if(this.question.options[this.selected.optionIndex].selected_tags.length){
         let sortedTags = []

        //  find Selected Products from All Products
          this.question.options[this.selected.optionIndex].selected_tags.forEach(selectedSingleTag=>{
               const selectedTags = this.tags.all.find(tag => tag.id === selectedSingleTag.product_tag_id)
  if (selectedTags) {
    sortedTags.push(selectedTags)
  }
          })

          // Find remaining non selected Products
          const otherTags =  this.tags.all.filter(tag => !this.question.options[this.selected.optionIndex].selected_tags.some(selectedSingleTag => selectedSingleTag.product_tag_id === tag.id))
          sortedTags = sortedTags.concat(otherTags)
         

         return sortedTags
      }else{
        return this.tags.all
      }
    },


    getTags() {
   
      return this.getSortedTags.filter(item => {
        return ((item.title.toLowerCase().includes(this.searchCollection.toLowerCase())))
      });
    }
  },
  data() {
    return {
      isLoadingImport:false,
      isLoadingBtn:false,
      isOpenVariant: false,
      skeletonCount: 5,
      searchCollection: '',
      isSelectAll: true,
      showResetBtn: false,
      tagProducts:[],
      isLoadingCollectionProducts:false
    }
  },
  methods: {

   async openVariant(title) {
    this.isLoadingCollectionProducts=true;
      this.isOpenVariant = true

      try {

          this.tagProducts = this.products.filter(product => {
              if(Array.isArray(product.tags) && product.tags.length){
                
                return product.tags.some(tag=>typeof tag === 'string' && tag.trim() !== '' && tag.trim().toLowerCase()==title.trim().toLowerCase())
              }
        })

        } catch (error) {
          console.log(error)
          if(error){
            this.$toasted.show("Opps, something went wrong! ", {
            theme: "toasted-primary",
            type: "error",
            position: "bottom-center",
            duration: 2000,
          });
          }
        }
      
        this.isLoadingCollectionProducts=false;
    },
    backFromVariant() {
      this.isOpenVariant = false
    },
    bulkSelectTags() {
      let tags = this.getTags;
      if (this.isSelectAll) {
        this.isSelectAll = false;
    
        tags.forEach(tag => {
          let checkDuplicate={
            product_tag_id: tag.id,
            question_option_id: this.question.options[
              this.selected.optionIndex
            ].id
          }
          if(!this.question.options[this.selected.optionIndex].selected_tags.includes(checkDuplicate))
          this.question.options[this.selected.optionIndex].selected_tags.push({
            product_tag_id: tag.id,
            question_option_id: this.question.options[this.selected.optionIndex].id
          })
        })
       
      }
      else {
        this.question.options[
          this.selected.optionIndex
        ].selected_tags = [];
        this.isSelectAll = true;
      }

    },


    isSelectedProduct(tag) {

      const findIndex = this.question.options[this.selected.optionIndex].selected_tags.findIndex((x) => x.product_tag_id == tag.id);
      if (findIndex > -1) {
        return true;
      } else {
        return false;
      }
    },

    checkFileType(file) {
      let fileType;
      let allowedImageExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
      let allowedVideoExtensions = /(\.mp4)$/i;

      if (!allowedImageExtensions.exec(file)) {
        if (allowedVideoExtensions.exec(file)) {
          fileType = "video";
        } else {
          fileType = "unknown";
        }
      } else {
        fileType = "image";
      }

      return fileType;
    },
    tagImage(image) {
      if (image)
        return image.startsWith('upload') ? '/' + image : image
      return 'https://images.quizell.com/default/default_image.png'
    },
    selectProduct(tag) {

      const index = this.question.options[
       this.selected.optionIndex
      ].selected_tags.findIndex(
        (productOption) => productOption.product_tag_id == tag.id
      );

      if (index > -1) {
        
        this.question.options[
       this.selected.optionIndex
      ].selected_tags.splice(index, 1);
      } else {
        const obj = {};
        obj.product_tag_id = tag.id;
        obj.question_option_id =
          this.question.options[
       this.selected.optionIndex
      ].id;

        this.question.options[
       this.selected.optionIndex
      ].selected_tags.push(obj);
      }
    },
  }
}
</script>
<style>
.tag-image {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  object-fit: cover;
  background-size: cover;
  padding: 0.25rem;
  background-color: #fff;
}

.tag-icon {
  color: #4d1b7e;

}

.tag-icon:hover {
  color: black;
}


.tagSearchInput {
  border: 0.8px solid #18191C14;
  box-sizing: border-box;
  border-radius: 6px;
  background: #ffffff;
}

.tagSearchInput input {
  background: transparent;
  border: none;
  outline: none;
}

.tagSearchInput input:focus {
  border: none;
  outline: none;
}

.filterBtn {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  align-items: center;
  letter-spacing: 0.04em;
  color: #4d1b7e;
  cursor: pointer
}

.filterBtn:hover {
  color: black;

}

.w-86 {
  width: 86%;
}

.tagBox {
  height: 50vh;
  /* border: 0.5px solid #b3afb6; */
  box-sizing: border-box;
  border-radius: 10px;
  overflow-y: scroll;
  scroll-behavior: smooth;
}

.tagBox .tag {
  border-radius: 6px;
}

.tagBox .tag:hover {
  background: #b3afb614;
}

.tag-btn {
  display: none;
  font-size: 14px;
  border: 1px solid #b3afb6;
  border-radius: 12px;
  outline: none

}

.selectedProduct .tag:hover .tag-btn {
  border: 1.5px solid #4d1b7e;
  color: #4d1b7e !important;
}
.selectedProduct .tag:hover .tag-btn .variant-text{
  color: #4d1b7e !important;
}
.selectedProduct .tag:hover .tag-btn .tag-text {
  color: #4d1b7e !important;
}

.tag:hover .tag-btn {
  display: block;
}

.singleProduct .title {
  color: #18191c;
  font-weight: 500;
}

.singleProduct .price {
  color: #4d4950;
  font-weight: 300;
}
</style>